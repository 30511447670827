import React from "react";
import { Link } from "gatsby";

function BrandsList(dataArray) {   
  return (
    <>
      <div>
        <h5>BRANDS</h5>

        <ul>
          {dataArray?.dataArray?.map((item) => (
            <li key={item?.id}>
              <Link to={item?.custom_url?.url}>
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>      
    </>
  );
}

export default BrandsList;
