import { useState } from 'react'
import Api from '../../services/Api'
// import useToastify from "../ui/useToastify";

export default function useGetPageData() {
  // const { toastMessage } = useToastify();
  const [isLoading, setIsLoading] = useState(false)

  async function getSiteMapPageData() {
    setIsLoading(true)
    const url = `/sitemap/data`

    const result = await getSiteMap(url)
    setIsLoading(false)
    return result
  }

  return {
    getSiteMapPageData,
    isLoading,
  }
}

async function getSiteMap(url) {
  const response = await Api.get(url)
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return {}
  }
  return response?.data
}
