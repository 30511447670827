import React from "react";
import { Link } from "gatsby";

function PageList(dataArray) { 
  console.log(dataArray, "dsds")
  return (
    <>
      <div className="">
        <h5 className="">PAGES</h5>

        <ul className="">
          {dataArray?.dataArray?.map((item) => (
            <li key={item.id}>
              <Link to={item.url} className="sd-link">
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>      
    </>
  );
}

export default PageList;
