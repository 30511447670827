import React from "react";
import { Link } from "gatsby";
import {
  default_categories_sidebar_navigation,
  default_brands_sidebar_navigation,
} from "../../../config/navigation";

function SidebarList() {
  const category_nav = process.env.GATSBY_sidebar_navigation2_id
    ? JSON.parse(process.env.GATSBY_sidebar_navigation1_id || "{}")[
        "navigations"
      ]
    : default_categories_sidebar_navigation;

  const brands_nav = process.env.GATSBY_sidebar_navigation3_id
    ? JSON.parse(process.env.GATSBY_sidebar_navigation2_id || "{}")[
        "navigations"
      ]
    : default_brands_sidebar_navigation;

  return (
    <>
      <div className="sidebar-block-bottom">
        <h5 className="sidebar-heading">Categories</h5>

        <ul className="sidebar-nav-list">
          {category_nav.map((item) => (
            <li key={item.label}>
              <Link to={item.url} className="sd-link">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="sidebar-block-bottom">
        <h5 className="sidebar-heading">Brands</h5>
        <ul className="sidebar-nav-list">
          {brands_nav.map((item) => (
            <li key={item.label}>
              <Link to={item.url} className="sd-link">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default SidebarList;
