import React from "react";
import { Link } from "gatsby";

function CategoriesList(dataArray) {
  return (
    <>
      <div>
        <h5>CATEGORIES</h5>

        <ul>
          {dataArray?.dataArray?.map((item) => (
            <>
              <li key={item?.id}>
                <Link to={item?.url}>
                  {item?.name}
                </Link>
              </li>
              {item?.child_categories.length ?
                <ul>
                  {item?.child_categories?.map((child) => (
                    <>
                      <li key={child?.id}>
                        <Link to={child?.url} >
                          {child?.name}
                        </Link>
                      </li>
                      {child?.child_categories.length ?
                        <ul className="">
                          {child?.child_categories?.map((sub_child) => (
                            <li key={sub_child?.id}>
                              <Link to={sub_child?.url} >
                                {sub_child?.name}
                              </Link>
                            </li>))} 
                            </ul> : <></>
                      }
                    </>
                  ))}
                </ul> : <></>
              }
            </>
          ))}
        </ul>
      </div>
    </>
  );
}

export default CategoriesList;
