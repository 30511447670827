import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import SidebarList from "../components/category/SidebarList";
import useGetPageData from "../hooks/sitemap/useGetPageData";
import { useLocation } from "@reach/router";
import PageList from "../components/sitemap/PagesList";
import BrandsList from "../components/sitemap/BrandsList";
import CategoriesList from "../components/sitemap/CategoriesList";
import Loader from "../components/form/Loader";
import { staticPage } from "../../config/staticPage";

function SiteMapPage() {
  const { getSiteMapPageData, isLoading } = useGetPageData();
  const location = useLocation();
  const [pages, setPages] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(async () => {
    const pageData = await getSiteMapPageData();

    if (pageData) {
      setPages(pageData?.pages);
      setBrands(pageData?.brands);
      setCategories(pageData?.categories);
    } else {
      console.log("no data");
    }
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>
        <div className="page-heading-section">
          <div className="container">
            <h2 className="page-title">{"SITEMAP"}</h2>
          </div>
        </div>

        <div className="container">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar hide-mobile">
                <SidebarList />
              </div>
              <div className="col page-content">
                <div className="flex flex-wrap sitemap-column-wrapper">
                  <div className="sitemap-col sitemap-page-column">
                    {" "}
                    {pages && pages?.length ? (
                      <PageList dataArray={pages} />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="sitemap-col sitemap-category-column">
                    {" "}
                    {categories && categories?.length ? (
                      <CategoriesList dataArray={categories} />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="sitemap-col sitemap-brands-column">
                    {" "}
                    {brands && brands?.length ? (
                      <BrandsList dataArray={brands} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "sitemap") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

SiteMapPage.Layout = Layout
export default SiteMapPage;
